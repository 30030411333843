<template>
	<div>
		<div v-if="loaded" class="tree-page opacity-page">
			<div class="header-tree">
				<div class="tree-actions">
					<div class="left-part">
						<div v-if="$route.params.slug" class="go-back-company btn-tbf white only-icon" @click="$router.push({name: 'company'})">
							<icon-arrow-back />
						</div>
						<div v-else class="go-back-company btn-tbf white only-icon" @click="$router.push({name: 'users'})">
							<icon-arrow-back />
						</div>
						<div class="dropdown settings-org" v-if="densityType != 'functional'">
							<button class="btn-tbf white only-icon tree-action-btn" @click="extendFilters = !extendFilters"><img src="../../../assets/filters.svg"></button>
						</div>

						<div class="dropdown departments-org" v-if="densityType != 'functional'">
							<button class="btn-tbf white only-icon tree-action-btn" @click="$root.$emit('open_modal_full_template', 'sidebar_departments')">
								<icon-department />
							</button>
						</div>
						
						<v-popover class="help" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}" v-if="densityType != 'functional'">
							<a class="btn-tbf blue only-icon help-btn" :href="helpData.link" target="_blank" v-if="helpData">
								<icon-question class="icon" />
							</a>

							<template slot="popover">
								<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
							</template>
						</v-popover>
						
						<button class="btn-tbf blue center only-icon download-tree" :class="[ densityType == 'functional' ? 'mg-0' : '']" id="btnExport" @click="exportTree">
							<div class="loader"></div>
							<icon-download class="icon" />
						</button>
						
						<div class="dropdown departments-org" v-if="densityType != 'functional'">
							<button class="btn-tbf blue only-icon tree-action-btn" @click="$root.$emit('open_modal_full_template', 'share_tree_chart')">
								<icon-share class="icon" />
							</button>
						</div>
					</div>

					<div v-if="$route.params.slug" class="center-part">
						<div class="text" v-if="$resize && $mq.above(600)">
							<router-link :to="{name: 'company', query: $route.query }"> {{ companyData.company_name ? companyData.company_name : 'Companie' }}</router-link> / {{companyData.name}}
						</div>
					</div>
					<div v-else class="center-part">
						<div class="text" v-if="$resize && $mq.above(600)">{{ companyData.company_name ? companyData.company_name : $t('companyData.private') }}</div>
					</div>

					<div class="right-part">
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<div class="text">{{ $t(`navbar.${densityType}`)}}</div>
								<div class="icon-filter"><icon-arrow /></div>
							</button>

							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="dropdown-item" v-for="typeItem in densitiesTypes" @click="changeDensity(typeItem)">
									<div class="checkbox">
										<span class="text">{{ $t(`navbar.${typeItem}`) }}</span>
									</div>
								</div>
							</div>
						</div>
					
						<div class="btns-zoom">
							<div class="zoom-minus" @click="parseFloat(zoomPage).toFixed(1) > 0.50 ? zoomPage += -0.1 : zoomPage" v-bind:class="{disabled: zoomPage == 0.50}">-</div>
							<div class="current-zoom" @click="zoomPage = 1">{{ parseFloat(zoomPage * 100).toFixed(0) }}%</div>
							<div class="zoom-plus" @click="parseFloat(zoomPage).toFixed(1) == 1.50 ? zoomPage : zoomPage += 0.1" v-bind:class="{disabled: zoomPage == 1.50}">+</div>
						</div>
					</div>
				</div>

				<div class="filters-extend" :class="[{mobile: $resize && $mq.between([0, 1024])}]" v-if="extendFilters">
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!filtered.role.length">{{ $t('filters.by_roles') }}</span>
							<span class="text" v-else-if="filtered.role.length == 1">{{ filterRoles.find(el => el.id == filtered.role[0].id).name }}</span>
							<span class="text" v-else>{{ filtered.role.length }} {{ $t('filters.roles_selected') }}</span>

							<div class="icon-filter" v-if="!filtered.role.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('role')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchRoles" :placeholder="$t('filters.search_roles')">
							</div>

							<template v-for="role in filteredRoles">
								<div class="dropdown-item" @click.stop="selectOptionFilter('role', role)" v-bind:class="{active: filtered.role.some(el => el.id == role.id)}" :key="'filter-role-' + role.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ role.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!filtered.workstation.length">{{ $t('filters.by_workstations') }}</span>
							<span class="text" v-else-if="filtered.workstation.length == 1">{{ filterWorkstations.find(el => el.id == filtered.workstation[0].id).name }}</span>
							<span class="text" v-else>{{ filtered.workstation.length }} {{ $t('filters.workstations_selected') }}</span>

							<div class="icon-filter" v-if="!filtered.workstation.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('workstation')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchWorkstations" :placeholder="$t('filters.search_workstations')">
							</div>

							<template v-for="workstation in filteredWorkstations">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('workstation', workstation)" v-bind:class="{active: filtered.workstation.some(el => el.id == workstation.id)}" :key="'filter-workstation-' + workstation.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ workstation.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!filtered.work_schedule.length">{{ $t('filters.by_work_schedules') }}</span>
							<span class="text" v-else-if="filtered.work_schedule.length == 1">{{ filterWorkSchedules.find(el => el.id == filtered.work_schedule[0].id).name }}</span>
							<span class="text" v-else>{{ filtered.work_schedule.length }} {{ $t('filters.workschedules_selected') }}</span>

							<div class="icon-filter" v-if="!filtered.work_schedule.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('work_schedule')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchWorkSchedules" :placeholder="$t('filters.search_workschedules')">
							</div>

							<template v-for="work_schedule in filteredWorkSchedules">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('work_schedule', work_schedule)" v-bind:class="{active: filtered.work_schedule.some(el => el.id == work_schedule.id)}" :key="'filter-work_schedule-' + work_schedule.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ work_schedule.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!filtered.language.length">{{ $t('filters.by_languages') }}</span>
							<span class="text" v-else-if="filtered.language.length == 1">{{ filterLanguages.find(el => el.id == filtered.language[0].id).name }}</span>
							<span class="text" v-else>{{ filtered.language.length }} {{ $t('filters.languages_selected') }}</span>

							<div class="icon-filter" v-if="!filtered.language.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('language')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchLanguages" :placeholder="$t('filters.search_languages')">
							</div>

							<template v-for="language in filteredLanguages">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('language', language)" v-bind:class="{active: filtered.language.some(el => el.id == language.id)}" :key="'filter-language-' + language.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ language.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!filtered.skill.length">{{ $t('filters.by_skills') }}</span>
							<span class="text" v-else-if="filtered.skill.length == 1">{{ filterSkills.find(el => el.id == filtered.skill[0].id).name }}</span>
							<span class="text" v-else>{{ filtered.skill.length }} {{ $t('filters.skills_selected') }}</span>

							<div class="icon-filter" v-if="!filtered.skill.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('skill')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchSkills" :placeholder="$t('filters.search_skills')">
							</div>

							<template v-for="skill in filteredSkills">
								<div class="dropdown-item" @click.stop="selectOptionFilter('skill', skill)" v-bind:class="{active: filtered.skill.some(el => el.id == skill.id)}" :key="'filter-skill-' + skill.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ skill.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
						<icon-trash  v-if="$resize && $mq.above(1025)"/>
						<div v-else-if="$resize && $mq.below(1024)">{{$t('filters.clear_all')}}</div>
					</button>
				</div>
			</div>

			<div class="scrollable-chart" v-dragscroll>
				<div class="center-box" id="divToImage">
					<TreeChart class="organigram-tbf" :json="Object.keys(companyData).length ? companyData : {}" :zoomPage="zoomPage" :key="treeChartKey" :filters="filtered" :style="'transform: scale(' + zoomPage + ');' " :promises="promisesObjectives" :densityType="densityType"/>
				</div>
			</div>
		</div>
		<organigram-loader v-else />
	</div>
</template>


<script type="text/javascript">
	import TreeChart from './TreeChart'
	import IconPlus from '../../Icons/Plus'
	import IconArrow from '../../Icons/Arrow'
	import IconClose from '../../Icons/Close'
    import IconTrash from '../../Icons/Trash'
	import IconArrowBack from '../../Icons/ArrowLong'
	import IconDepartment from '../../Icons/Department'
	import IconDownload from '../../Icons/Download'
	import IconQuestion from '../../Icons/Question'
	import IconShare from '../../Icons/Share.vue'
	import OrganigramLoader from '../../PagesLoaders/OrganigramLoader'
	import * as htmlToImage from 'html-to-image'
	import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
	import download from 'downloadjs'

	import html2canvas from 'html2canvas';

	export default {
		data() {
			return {
				loaded: false,
				extendFilters: false,
				filterRoles: [],
				searchRoles: '',
            	filterWorkstations: [],
            	searchWorkstations: '',
            	filterWorkSchedules: [],
            	searchWorkSchedules: '',
            	filterLanguages: [],
            	searchLanguages: '',
            	filterSkills: [],
            	searchSkills: '',
				filtered: {
					role: [],
					workstation: [],
					work_schedule: [],
					language: [],
					skill: []
				},
				companyData: {},
				totalChildrensJson: 0,
      			treeChartKey: 1,
      			zoomPage: 1,
      			extendItems: true,
      			companyData_details: {},
      			promisesObjectives: { ready: false },
				helpData: this.$store.getters['help_links/currentLink'](this.$route.name),
				densityType: 'minimal',
				densitiesTypes: [ 'structural', 'functional', 'minimal', 'compact', 'detailed' ]
			};
		},
		components: {
			TreeChart,
			IconPlus,
			IconArrow,
			IconTrash,
			IconClose,
			IconArrowBack,
			OrganigramLoader,
			IconDownload,
			IconDepartment,
			IconQuestion,
			IconShare
		},
		watch:{
			$route (to, from){
				var jsonExtend = []
				localStorage.setItem('numberCount', 0)
				localStorage.setItem('numberCountExtend', 0)
				localStorage.setItem('jsonExtend', jsonExtend)

				if(to.name != from.name){
					this.getCompany()
				}
            },
            soft_applications(newData, oldData) {
				newData.map((el) => {
					if(el.application && el.application.name == 'Obiective'){
						this.getPromises()
					}
				})
			}
        },
        computed: {
        	filteredRoles(){
				return getByKeywordFilter(this.filterRoles, this.searchRoles)
			},
			filteredWorkstations(){
				return getByKeywordFilter(this.filterWorkstations, this.searchWorkstations)
			},
			filteredWorkSchedules(){
				return getByKeywordFilter(this.filterWorkSchedules, this.searchWorkSchedules)
			},
			filteredLanguages(){
				return getByKeywordFilter(this.filterLanguages, this.searchLanguages)
			},
			filteredSkills(){
				return getByKeywordFilter(this.filterSkills, this.searchSkills)
			},
			soft_applications() {
				return this.$store.getters['applications/activeApplications']
			}
        },
		async mounted() {
			var jsonExtend = []
			localStorage.setItem('numberCount', 0)
			localStorage.setItem('numberCountExtend', 0)
			localStorage.setItem('jsonExtend', jsonExtend)

			this.densityType = this.$route.query.type ? this.$route.query.type : 'minimal'

			await this.getFilters()
			if (this.densityType == 'functional') {
				this.getFunctionalChart()
			} else {
				await this.getCompany()
			}

			this.$root.$on("refreshCompanyChart", (oldMG = false) => {
				this.getCompany()
            });

			if(this.soft_applications.length) {
				this.soft_applications.map((el) => {
					if(el.application && el.application.name == 'Obiective'){
						this.getPromises()
					}
				})
			}
			
		},
		beforeDestroy () {
			this.$root.$off('refreshCompanyChart')
		},
		methods: {
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {roles: true, workstations: true, work_schedules: 'all', foreign_languages: true, skills: true }})
				.then(({data}) => {
					this.filterRoles = data.data.roles
        			this.filterWorkstations = data.data.workstations
        			this.filterWorkSchedules = data.data.work_schedules
        			this.filterSkills = data.data.skills
        			this.filterLanguages = data.data.foreign_languages
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			async getCompany(){
				var link;
				
				if(this.$route.params.slug) {
					link = '/chart/' + this.$route.params.slug;
				} else {
					link = '/chart';
				}

				await axios.get(this.$auth.user().instance.id + link).then(({data}) => {
					this.companyData = data.data
					localStorage.setItem('numberCount', 0)
					this.countTotalItems(this.companyData)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).then(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			countTotalItems(jsonData){
				this.totalChildrensJson += 1
				if(jsonData.hasOwnProperty('children') && jsonData.children.length){
					jsonData.children.forEach(el => {
						this.countTotalItems(el)
					})
				}
				localStorage.setItem('numberCountExtend', this.totalChildrensJson)
			},
			updateTree(){
				this.treeChartKey += 1
			},
			openSidebar(){
				this.$root.$emit('open_modal_full_template', 'sidebar_org', false, this.companyData_details)
			},
			extendTree(){
				localStorage.setItem('numberCountExtend', 0)

				setTimeout(() => {
					this.extendItems = !this.extendItems
					this.$root.$emit('toggleExtendAll', this.extendItems)
				}, 0)
			},
			selectOptionFilter(filter, value) {
				var obj = {id: value.id, name: value.name}

				if(this.filtered[filter].some(el => el.id == obj.id)){
					this.filtered[filter].splice(this.filtered[filter].findIndex(el => el.id == obj.id), 1)
				}else{
					this.filtered[filter].push(obj)
				}
			},
			clearFilter(filter){
				if(filter == 'all'){
					 this.filtered.skill = [] 
					 this.filtered.role = [] 
					 this.filtered.workstation = [] 
					 this.filtered.work_schedule = [] 
					 this.filtered.language = []
				}else{
					this.filtered[filter] = []
				}
			},
			exportTree(){
				var buttonName = `btnExport`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')

				htmlToImage.toPng(document.getElementById('divToImage'))
				.then((dataUrl) => {
					download(dataUrl, `${ this.companyData.company_name ? this.companyData.company_name : this.$t('companyData.private') }.png`);
				})
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmit.disabled = false
						}, 500)
					}, 300)
				})

				// html2canvas(document.getElementById("divToImage"), {
				// 	allowTaint: true,
				// 	useCORS: true
				// })
				// .then((canvas) => {
				// 	var anchorTag = document.createElement("a");
				// 	anchorTag.download = `${ this.companyData.company_name ? this.companyData.company_name : this.$t('companyData.private') }.jpg`;
				// 	anchorTag.href = canvas.toDataURL();
				// 	anchorTag.target = '_blank';
				// 	anchorTag.click();

				// 	btnSubmitLoader.classList.add('finish')
				// 	setTimeout(()=>{
				// 		btnSubmit.classList.add('completed')
				// 		btnSubmitLoader.classList.remove('onProgress', 'finish')
				// 		btnSubmit.classList.remove('loading')
				// 		setTimeout(()=>{
				// 			btnSubmit.classList.remove('completed')
				// 			btnSubmit.disabled = false
				// 		}, 500)
				// 	}, 300)
				// });
			},
			getPromises(){
				axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/${this.$auth.user().master_instance_id}/promises-status`)
				.then(({data}) => {
					this.promisesObjectives = {ready: true, users: data.data}
				})
			},
			changeDensity(type){
				if(this.densityType == 'functional' || type == 'functional') {
					this.loaded = false
					if (type == 'functional') {
						this.getFunctionalChart()
					} else {
						this.getCompany()
						.then(() => {
							this.loaded = true
						})
					}
				}

				this.densityType = type
				this.$router.replace({ query: { type: type} })
			},
			getFunctionalChart(){
				axios.get(`/${this.$auth.user().instance.id}/job-function/chart`)
					.then(({data}) => {
						this.companyData = data.data
						this.countTotalItems(this.companyData)
					}).finally(() => {
						setTimeout(() => {
							this.loaded = true
							setTimeout(() => {
								$('.opacity-page').addClass('show')
							}, 0)
						}, 0)
					})
			}
		}
	};

	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>